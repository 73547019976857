import { Show, ErrorBoundary, Suspense } from "solid-js";
import {
  createAsync,
  type RouteSectionProps,
  type RouteDefinition,
} from "@solidjs/router";
import { useProduct } from "~/services/products/useProduct";
import { Meta } from "@solidjs/meta";
import { ProductPriceResponse } from "~/services/roma-api/products/types";
import {
  ProductHeader,
  ProductDimensionsDetail,
  ProductColourSelector,
  ProductDescription,
  ProductDetails,
  BuiltFrameCareInstr,
} from "~/components/product/product-page";
import { useSessionContext } from "~/utils/contexts";
import { InfoBox, BaseSkeleton } from "~/components/utility";
import { PT } from "~/utils/products";
import { getSession } from "~/services/session";

export const route = {
  preload: ({ params }) =>
    useProduct(params.sku, {
      available_list: true,
      suggestions: true,
      type: PT.MIRROR,
    }),
} satisfies RouteDefinition;

export type FormattedPricing = ProductPriceResponse & {
  availableAs?: string[];
  defaultPlantLowStock?: boolean;
  anyPlantLowStock?: boolean;
};

export default function DefaultProduct(props: RouteSectionProps) {
  const { isPartner } = useSessionContext();

  const product = createAsync(() =>
    useProduct(props.params.sku, {
      available_list: true,
      suggestions: true,
      type: PT.MIRROR,
    })
  );

  const currency = createAsync(async () => {
    const session = await getSession();
    return session?.currency;
  });

  const defaultType = () => {
    if (currency() === "CAD" && product.latest?.Category === "Roma Elite") {
      return PT.JOIN;
    }
    return PT.LENGTH;
  };

  return (
    <>
      <ErrorBoundary
        fallback={(err) => {
          // TODO - Error-Boundary
          return <div>An error occurred.</div>;
        }}
      >
        <div class="!border-t-0">
          <Meta name="Algolia crawler" content="noindex" />
          <ProductHeader product={product} />
          <Suspense fallback={<BaseSkeleton height={221} />}>
            <Show when={isPartner() && product()}>
              <h3 class="text-xl mt-2">Prices vary</h3>
            </Show>
          </Suspense>
        </div>

        <ProductDimensionsDetail product={product} />

        <div class="grid grid-cols-product-headers items-center text-sm">
          <p class="font-bold">Finish</p>
          <div class="flex items-baseline gap-3 font-medium text-sm tracking-wider">
            <p>{product()?.Finish}</p>
          </div>
        </div>

        <ProductColourSelector product={product} type={PT.MIRROR} />
        <div class="flex flex-col">
          <InfoBox class="mb-4">
            Please contact our customer care team at{" "}
            <a href="tel:18002632322" rel="nofollow" class="text-roma-blue">
              1-800-263-2322
            </a>{" "}
            for all mirror quotes and finalizing your order.
          </InfoBox>
          <ProductDescription description={product()?.Description as string} />
        </div>
        <ProductDetails product={product} openOnLoad={true} />
        <BuiltFrameCareInstr />
      </ErrorBoundary>
    </>
  );
}
